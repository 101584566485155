<template>
  <div>
    <v-row class="match-height">
      <v-col
        v-for="data in statisticsData"
        :key="data.title"
        cols="4"
        md="4"
        sm="4"
        xs="4"
        class="align-center"
      >
        <statistics-single-card
          :title="data.title"
          :color="data.color"
          :icon="data.icon"
          :value="data.value"
        ></statistics-single-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db1 } from "@/firebase";
import {
  collection,
  query,
  where,
  Timestamp,
  getCountFromServer,
} from "firebase/firestore";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
export default {
  name: "leaves-statistics-overview",
  components: {
    StatisticsSingleCard,
  },
  data() {
    return {
      statisticsData: [
        {
          title: "Aktiv",
          icon: "mdi-run-fast",
          color: "red",
          value: null,
        },
        {
          title: "Nächste 3 Tage",
          icon: "mdi-run",
          color: "orange",
          value: null,
        },
        {
          title: "Nächste 10 Tage",
          icon: "mdi-run",
          color: "blue",
          value: null,
        },
      ],
    };
  },
  created() {
    this.getLeavesCount();
  },
  methods: {
    async getLeavesCount() {
      // Get current date
      const now = Timestamp.now();

      // Get date 3 days from now
      const threeDaysFromNow = Timestamp.fromDate(
        new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
      );

      // Get date 10 days from now
      const tenDaysFromNow = Timestamp.fromDate(
        new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
      );

      const leavesCollection = collection(
        db1,
        "organizations",
        this.$route.params.organizationId,
        "leaves"
      );

      // Get all leaves
      const allLeavesSnapshot = await getCountFromServer(leavesCollection);
      const allLeavesCount = allLeavesSnapshot.data().count;

      // Get leaves after start
      const leavesAfterStartQuery = query(
        leavesCollection,
        where("duration.start.timestamp", ">=", now)
      );
      const leavesAfterStartSnapshot = await getCountFromServer(
        leavesAfterStartQuery
      );
      const leavesAfterStartCount = leavesAfterStartSnapshot.data().count;

      // Get leaves before end
      const leavesBeforeEndQuery = query(
        leavesCollection,
        where("duration.end.timestamp", "<=", now)
      );
      const leavesBeforeEndSnapshot = await getCountFromServer(
        leavesBeforeEndQuery
      );
      const leavesBeforeEndCount = leavesBeforeEndSnapshot.data().count;

      // Calculate leaves in range
      const leavesInRange =
        allLeavesCount - leavesAfterStartCount - leavesBeforeEndCount;

      const queryUpcomingLeaves3 = query(
        collection(
          db1,
          "organizations",
          this.$route.params.organizationId,
          "leaves"
        ),
        where("duration.start.timestamp", ">=", now),
        where("duration.start.timestamp", "<=", threeDaysFromNow)
      );

      const queryUpcomingLeaves10 = query(
        collection(
          db1,
          "organizations",
          this.$route.params.organizationId,
          "leaves"
        ),
        where("duration.start.timestamp", ">=", now),
        where("duration.start.timestamp", "<=", tenDaysFromNow)
      );

      const snapshotQueryUpcomingLeaves3 = await getCountFromServer(
        queryUpcomingLeaves3
      );
      const snapshotQueryUpcomingLeaves10 = await getCountFromServer(
        queryUpcomingLeaves10
      );
      this.statisticsData[0].value = leavesInRange;
      this.statisticsData[1].value = snapshotQueryUpcomingLeaves3.data().count;
      this.statisticsData[2].value = snapshotQueryUpcomingLeaves10.data().count;
    },
  },
};
</script>
