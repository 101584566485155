<template>
  <v-alert dense text prominent type="error" icon="mdi-run-fast" elevation="2">
    <v-row align="center">
      <v-col class="grow">
        <div class="title">Aktive Abmeldung</div>
        Gültig bis: {{ timestampConverter(end) }} Uhr
        <br />
        <v-btn
          depressed
          color="error"
          small
          :to="{ name: 'availability-leaves-details', params: { itemId: id } }"
          class="mt-2 mr-2 hidden-md-and-up"
          ><v-icon left>mdi-eye</v-icon>Öffnen</v-btn
        >
        <v-btn
          depressed
          color="error"
          small
          :to="{ name: 'availability-leaves-edit', params: { itemId: id } }"
          class="mt-2 hidden-md-and-up"
          ><v-icon left>mdi-pencil</v-icon>Bearbeiten</v-btn
        >
      </v-col>
      <v-col class="shrink hidden-sm-and-down"
        ><v-btn
          depressed
          color="error"
          :to="{ name: 'availability-leaves-details', params: { itemId: id } }"
          ><v-icon left>mdi-eye</v-icon>Öffnen</v-btn
        ></v-col
      >
      <v-col class="shrink hidden-sm-and-down">
        <v-btn
          depressed
          color="error"
          :to="{ name: 'availability-leaves-edit', params: { itemId: id } }"
          ><v-icon left>mdi-pencil</v-icon>Bearbeiten</v-btn
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "active-leave-card",
  props: {
    end: {
      type: Object, // Firestore Timestamp
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
