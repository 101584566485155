<template>
  <div>
    <v-card height="800px" class="overflow-hidden">
      <v-toolbar height="64" outlined flat>
        <v-btn outlined small @click="prev" class="ml-2 mr-2">
          <v-icon dense>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn outlined small @click="next" class="mr-4">
          <v-icon dense>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title>{{ calendarTitle }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="showAllLeaves"
              outlined
              v-bind="attrs"
              v-on="on"
              class="mr-4"
            >
              <span>
                <v-icon left small>mdi-calendar-filter</v-icon>
                {{ leavesFilter }}
              </span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list nav>
            <v-list-item-group v-model="leavesFilter" mandatory>
              <v-list-item link dense value="Alle">
                <v-list-item-icon>
                  <v-icon dense>mdi-all-inclusive</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  >Alle Abwesenheiten anzeigen</v-list-item-title
                >
              </v-list-item>
              <v-list-item link dense value="Eigene">
                <v-list-item-icon>
                  <v-icon dense>mdi-filter</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  >Nur eigene Abwesenheiten anzeigen</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn outlined class="mr-4" @click="setToday"> Heute </v-btn>
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Tag</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Woche</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Monat</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 Tage</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="type = 'category'">
                        <v-list-item-title>Kategorie</v-list-item-title>
                      </v-list-item> -->
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="preparedLeaves"
        color="primary"
        locale="de"
        show-week
        show-month-on-first
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :short-weekdays="calShortWeekdays"
        :short-months="calShortMonths"
        event-name="title"
        :event-margin-bottom="3"
        :now="today"
        :type="type"
        event-category="type.title"
        :event-color="getLeaveColor"
        @click:event="showLeave"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      >
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        max-width="500px"
        offset-x
      >
        <leave-details-card
          :leave="selectedLeave"
          showCloseButton
          showDetailsButton
          minWidth="440px"
          maxWidth="500px"
          @closeEvent="selectedOpen = false"
        ></leave-details-card>
      </v-menu>
    </v-card>
  </div>
</template>

<script>
import LeaveDetailsCard from "@/components/availability/leaves/LeaveDetailsCard.vue";
import { AVAILABILITY_LEAVES_read_all } from "@/data/permission-types.js";
export default {
  name: "leaves-calendar",
  props: {
    leaves: {
      type: Array,
      required: true,
    },
  },
  components: { LeaveDetailsCard },
  data() {
    return {
      search: "",
      leavesPersonFilter: "own",

      leavesFilter: "Eigene",
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: "month",
      value: "",
      ready: false,
      typeToLabel: {
        month: "Monat",
        week: "Woche",
        day: "Tag",
        "4day": "4 Tage",
        // "category": "Kategorien"
      },
      title: null,
      start: null,
      end: null,
      color: "#1976D2", // default event color
      selectedLeave: null,
      selectedElement: null,
      selectedOpen: false,
      calShortMonths: false,
      calShortWeekdays: false,
    };
  },
  computed: {
    preparedLeaves() {
      return this.leaves.map((leave) => {
        return {
          ...leave,
          title: leave.user.displayName,
          start: leave.duration.start.timestamp.toDate().toLocaleString("sv"),
          end: leave.duration.end.timestamp.toDate().toLocaleString("sv"),
          color: this.getLeaveColor(leave),
        };
      });
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    calendarTitle() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      // const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year;
      const endYear = end.year;
      // const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day; //+ this.nth(start.day)
      const endDay = end.day; //+ this.nth(end.day)
      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return `${startDay}. ${startMonth} ${startYear} bis ${endDay}. ${endMonth} ${endYear}`;
        case "4day":
          return `${startDay}. ${startMonth} ${startYear} bis ${endDay}. ${endMonth} ${endYear}`;
        case "day":
          return `${startDay}. ${startMonth} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
    showAllLeaves() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_read_all}`
      );
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showLeave({ nativeEvent, event }) {
      const open = () => {
        this.selectedLeave = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d;
      // return d> 3 && d < 21
      //   ? 'th'
      //   : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    getLeaveColor(leave) {
      const reasons = [
        { id: "0", title: "Keine Angabe", description: "", color: "blue-grey" },
        {
          id: "1",
          title: "Verpflichtungen/Verbindlichkeiten/Termine",
          description: "Bspw. berufliche, private, familiäre Verpflichtungen",
          color: "orange",
        },
        {
          id: "2",
          title: "Nicht in Stadt",
          description: "Nicht erreichbar",
          color: "blue",
        },
        {
          id: "3",
          title: "Keine Diensttauglichkeit",
          description: "Bspw. aufgrund von Krankheit, Verletzung, Ablauf G26.3",
          color: "purple",
        },
        {
          id: "4",
          title: "Eingeschränkte Diensttauglichkeit",
          description: "Bspw. duch Ablauf von Prüfdaten",
          color: "indigo",
        },
        {
          id: "8",
          title: "Beurlaubung",
          description: "Ruhen von Rechten und Pflichten",
          color: "brown",
        },
        { id: "9", title: "Sonstiges", description: "", color: "blue-grey" },
      ];

      const reason = reasons.find((reason) => reason.id === leave.reason.id);
      return reason ? reason.color : "grey";
    },
  },
};
</script>
